import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';

function QuestionnaireForm({
  inputs,
  handleSubmit,
  handleChange,
  navigateToPreviousPage,
}) {
    const [displayedPhoneNumber, setDisplayedPhoneNumber] = useState('');

    const emergencyPhoneRef = createRef();
    const handlePhoneNumberChange = (event) => {
        event.preventDefault();
        var numberOnly = event.target.value.replaceAll(/\D{1}/g, '');
        var displayedNumber = '';
        if (numberOnly.length !== 0) {
            if (numberOnly.length <= 3) {
                displayedNumber = "(" + numberOnly;
            } else if (numberOnly.length <= 6) {
                displayedNumber = "(" + numberOnly.substring(0, 3) + ") " + numberOnly.substring(3);
            } else if (numberOnly.length <= 10) {
                displayedNumber = "(" + numberOnly.substring(0, 3) + ") " + numberOnly.substring(3, 6) + "-" + numberOnly.substring(6);
            } else {
                var len = numberOnly.length;
                displayedNumber = "+" + numberOnly.substring(0, len - 10) + " (" + numberOnly.substring(len - 10, len - 7) + ") " + numberOnly.substring(len - 7, len - 4) + "-" + numberOnly.substring(len - 4);
            }
        }

        setDisplayedPhoneNumber(displayedNumber);

        emergencyPhoneRef.current.value = numberOnly;
        handleChange({ target: emergencyPhoneRef.current });
    }

  return (
    <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
            <h2 className="font-medium text-md">Emergency Contact</h2>
            <hr className="pb-2" />
            <label className="py-2">
                <span className="block text-sm font-medium text-slate-700">
                    Emergency Contact Name
                  </span>
                  <input type="text"
                      name="emergencyContactName"
                      value={inputs.questionnaire.emergencyContactName || ""}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                        invalid:border-pink-500 invalid:text-pink-600
                                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                      required
                  />
            </label>
            <label className="py-2">
                <span className="block text-sm font-medium text-slate-700">
                    Emergency Contact Phone Number
                  </span>
                  <input type="text"
                      name="emergencyContactPhoneNumberVisible"
                      value={displayedPhoneNumber || inputs.questionnaire.emergencyContactPhoneNumber || ""}
                      onChange={handlePhoneNumberChange}
                      className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                        invalid:border-pink-500 invalid:text-pink-600
                                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                      required
                  />
                  <input type="tel"
                      ref={emergencyPhoneRef}
                      className='hidden'
                      name="emergencyContactPhoneNumber"
                      onChange={handleChange}
                      value={inputs.questionnaire.emergencyContactPhoneNumber || ""} />
            </label>

            <h2 className="pt-10 font-medium text-md">Questionnaire</h2>
            <hr className="pb-2" />
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            Are you willing to submit to a pre-employment background and
            substance screening?
          </span>
          <select
            name="backgroundCheck"
            value={inputs.questionnaire.backgroundCheck || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option disabled className="none"></option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </label>
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            Are you able to perform the essential functions of the job for which
            you are applying, with or without a reasonable accommodation?
          </span>
          <select
            name="accommodations"
            value={inputs.questionnaire.accommodations || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option disabled className="none"></option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </label>
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            Are you authorized to work in the United States?
          </span>
          <select
            name="workAuthorization"
            value={inputs.questionnaire.workAuthorization || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option disabled className="none"></option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </label>
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            Are you 18 or older?
          </span>
          <select
            name="workingAge"
            value={inputs.questionnaire.workingAge || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option disabled className="none"></option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </label>
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
                      Have you ever applied or worked for Leadpoint before? <span className="text-sm font-bold">IF THIS APPLIES TO YOU, PLEASE CALL OUR RECRUITING DEPARTMENT AT 1-855-802-1741</span>
          </span>
          <select
            name="previouslyApplied"
            value={inputs.questionnaire.previouslyApplied || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option disabled className="none"></option>
            <option>Yes</option>
            <option>No</option>
          </select>
        </label>
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            How did you hear about this position?
          </span>
          <select
            name="referralSource"
            value={inputs.questionnaire.referralSource || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option disabled className="none"></option>
            <option label="Job Fair" value="Job Fair">
              Job Fair
            </option>
            <option
              label="Referral/Employee Referral"
              value="Referral/Employee Referral"
            >
              Referral/Employee Referral
            </option>
            <option label="Glassdoor" value="Glassdoor">
              Glassdoor
            </option>
            <option label="Text Blast" value="Text Blast">
              Text Blast
            </option>
            <option label="Twitter" value="Twitter">
              Twitter
            </option>
            <option label="Friend/Family" value="Friend/Family">
              Friend/Family
            </option>
            <option label="Walk into Site" value="Walk into Site">
              Walk into Site
            </option>
            <option label="ZipRecruiter" value="ZipRecruiter">
              ZipRecruiter
            </option>
            <option label="CareerBuilder" value="CareerBuilder">
              CareerBuilder
            </option>
            <option label="Craigslist" value="Craigslist">
              Craigslist
            </option>
            <option label="Google Jobs" value="Google Jobs">
              Google Jobs
            </option>
            <option label="Indeed" value="Indeed">
              Indeed
            </option>
            <option label="LeadpointUSA.com" value="LeadpointUSA.com">
              LeadpointUSA.com
            </option>
            <option label="Google Search" value="Google Search">
              Google Search
            </option>
            <option label="Unemployment Center" value="Unemployment Center">
              Unemployment Center
            </option>
            <option label="Facebook" value="Facebook">
              Facebook
            </option>
            <option label="Other" value="Other">
              Other
            </option>
          </select>
        </label>
        <div className="flex justify-between flex-col md:flex-row">
          <button
            onClick={navigateToPreviousPage}
            className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-slate-400 text-white font-semibold rounded-lg shadow-md hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          >
            Back
          </button>

          <input
            type="submit"
            value="Next"
            className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          />
        </div>
      </div>
    </form>
  );
}


QuestionnaireForm.propTypes = {
    inputs: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    navigateToPreviousPage: PropTypes.func
}

export default QuestionnaireForm;
