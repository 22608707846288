import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

const browserHistory = createBrowserHistory({ basename: '' });

var reactPlugin = new ReactPlugin();
/*var clickPluginInstance = new ClickAnalyticsPlugin();
var clickPluginConfig = {
     autoCapture: true
};*/
var appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        //extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
            // *** Add the Click Analytics plug-in. ***
            // [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };