import React, { useState, createRef } from 'react';
import { Country, State } from 'country-state-city';
import PropTypes from 'prop-types';

function PersonalInformationForm({ inputs, handleSubmit, handleChange, handleResumeChange, resumeUpload, shouldAllowResume }) {
    const [selectedCountry, setSelectedCountry] = useState(inputs.personalInfo.countryCode || '');
    const otherCountries = Country.getAllCountries();
    const states = State.getStatesOfCountry(selectedCountry);
    const [displayedPhoneNumber, setDisplayedPhoneNumber] = useState('');

    const countryCodeRef = createRef();
    const handleCountryChange = (event) => {
        var selectedIndex = event.target.selectedIndex;
        var option = event.target.options[selectedIndex];
        var countryCode = option.getAttribute("data-countrycode");
        setSelectedCountry(countryCode);
        handleChange(event);
        countryCodeRef.current.value = countryCode;
        handleChange({ target: countryCodeRef.current });
    };

    const mobilePhoneRef = createRef();
    const handlePhoneNumberChange = (event) => {
        event.preventDefault();
        var numberOnly = event.target.value.replaceAll(/\D{1}/g, '');
        var displayedNumber = '';
        if (numberOnly.length !== 0) {
            if (numberOnly.length <= 3) {
                displayedNumber = "(" + numberOnly;
            } else if (numberOnly.length <= 6) {
                displayedNumber = "(" + numberOnly.substring(0, 3) + ") " + numberOnly.substring(3);
            } else if (numberOnly.length <= 10) {
                displayedNumber = "(" + numberOnly.substring(0, 3) + ") " + numberOnly.substring(3, 6) + "-" + numberOnly.substring(6);
            } else {
                var len = numberOnly.length;
                displayedNumber = "+" + numberOnly.substring(0, len - 10) + " (" + numberOnly.substring(len - 10, len - 7) + ") " + numberOnly.substring(len - 7, len - 4) + "-" + numberOnly.substring(len - 4);
            }
        }

        setDisplayedPhoneNumber(displayedNumber);
    
        mobilePhoneRef.current.value = numberOnly;
        handleChange({target : mobilePhoneRef.current});
    }

    return (
        <form onSubmit={handleSubmit}>
                    <div className="flex flex-col">
                        <h2 className="font-medium text-md">Personal Information</h2>
                        <hr className="pb-2"/>
                        <label className="py-2"> 
                            <span className="block text-sm font-medium text-slate-700">First Name</span>
                            <input type="text"
                                name="firstName"
                                value={inputs.personalInfo.firstName || ""}
                                onChange={handleChange}
                                placeholder='First Name...'
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500
                                peer"
                                required
                                />
                            <p className="mt-2 hidden invisible peer-invalid:visible peer-invalid:block text-pink-600 text-sm">Please enter your first name.</p>
                        </label>
                        <label className="py-2"> 
                            <span className="block text-sm font-medium text-slate-700">Last Name</span>
                            <input type="text"
                                name="lastName"
                                value={inputs.personalInfo.lastName || ""}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                                required
                            />
                        </label>
                        <label className="py-2"> 
                            <span className="block text-sm font-medium text-slate-700">Address</span>
                            <input type="text"
                                name="address"
                                value={inputs.personalInfo.address || ""}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                            />
                        </label>
                        <label className="py-2">
                            <span className="block text-sm font-medium text-slate-700">City</span>
                            <input type="text"
                                name="city"
                                value={inputs.personalInfo.city || ""}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                            />
                        </label>
                        <label className="py-2">
                            <span className="block text-sm font-medium text-slate-700">Postal Code</span>
                            <input type="text"
                            name="postalcode"
                                value={inputs.personalInfo.postalcode || ""}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                        invalid:border-pink-500 invalid:text-pink-600
                                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                                required
                            />
                        </label>

                        <label className="py-2">
                            <span className="block text-sm font-medium text-slate-700">Country</span>
                            <select
                                name="country"
                                value={inputs.personalInfo.country || ""}
                                onChange={handleCountryChange}
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                        invalid:border-pink-500 invalid:text-pink-600
                                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                                required
                            >
                                <option disabled className="none"></option>
                                <option key="US" data-countrycode="US">United States</option>
                                {
                                    otherCountries.map((country) => {
                                        return (<option key={country.isoCode} data-countrycode={country.isoCode}>{country.name}</option>)
                                    })
                                }
                            </select>
                        </label>
                        
                        <label className="py-2">
                            <span className="block text-sm font-medium text-slate-700">State</span>
                            <select
                                name="state"
                                value={inputs.personalInfo.state || ""}
                                onChange={handleChange}
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                        focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                        disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                        invalid:border-pink-500 invalid:text-pink-600
                                        focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                                required
                            >
                                <option disabled className="none"></option>
                                {
                                    states.map((state) => {
                                        return (<option key={state.isoCode}>{state.name}</option>)
                                    })
                                }
                            </select>
                        </label>
                        <input type="hidden" name="countryCode" value={selectedCountry} ref={countryCodeRef } />
                        
                        <h2 className="pt-10 font-medium text-md">Contact Information</h2>
                        <hr className="pb-2"/>
                        <label className="py-2">
                            <span className="block text-sm font-medium text-slate-700">Mobile Phone Number</span>
                            <input type="text"
                                name="mobilePhoneVisible"
                                value={displayedPhoneNumber || inputs.personalInfo.mobilePhone ||""}
                                onChange={handlePhoneNumberChange}
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                            />
                            <input type="tel"
                                ref={mobilePhoneRef}
                                className='hidden' 
                                name="mobilePhone"
                                onChange={handleChange}
                                value={inputs.personalInfo.mobilePhone || ""}/>
                        </label>
                        <label className="py-2"> 
                            <span className="block text-sm font-medium text-slate-700">Email</span>
                            <input type="text"
                                name="email"
                                value={inputs.personalInfo.email || ""}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                invalid:border-pink-500 invalid:text-pink-600
                                focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                            />
                        </label>
                        {shouldAllowResume &&
                            <>
                                <h2 className="pt-10 font-medium text-md">Resume</h2>
                                <hr className="pb-2"/>
                                <label name="resume" className="pb-4">
                                    <input type="file"
                                        name="resume"
                                        ref={resumeUpload}
                                        onChange={handleResumeChange}
                                        accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                        className="block w-full text-sm text-slate-500
                                                file:mr-4 file:py-2 file:px-4
                                                file:rounded-full file:border-0
                                                file:text-sm file:font-semibold
                                                file:bg-red-50 file:text-red-700
                                                hover:file:bg-red-100"
                                        required
                                    />
                                </label>
                            </>
                        }
                        <input type="submit"
                            value="Next"
                            className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"  
                        />
                    </div>
                    </form>
    )
}


PersonalInformationForm.propTypes = {
    inputs: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    navigateToPreviousPage: PropTypes.func,
    handleResumeChange: PropTypes.func,
    resumeUpload: PropTypes.object,
    shouldAllowResume: PropTypes.bool
}

export default PersonalInformationForm;