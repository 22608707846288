import React, { useState, useEffect, createRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from '../assets/leadpoint-logo.png'
import PersonalInformationForm from './forms/PersonalInformationForm';
import QuestionnaireForm from './forms/QuestionnaireForm';
import EqualOpportunityForm from './forms/EqualOpportunityForm';
import AcknowledgmentForm from './forms/AcknowledgmentForm';
import ReviewForm from './forms/ReviewForm';
import { ReactComponent as LoadingSpinner } from "../assets/loading_spinner.svg";
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';


const apiUrl = process.env.REACT_APP_APPLICATION_URL;
const completionApiUrl = process.env.REACT_APP_FINAL_UPDATE_URL;
const jobPostApiUrl = process.env.REACT_APP_JOB_POSTING_URL;
const leadpointJobBoardUrl = process.env.REACT_APP_LEADPOINT_JOB_BOARD_URL;

const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    if (decodedCookie.includes(';')) {
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
    }
    return "";
}

function Home() {
    const [queryParameters] = useSearchParams();
    const jobIDParameter = queryParameters.get("jobId");
    let postIDParameter = queryParameters.get("postId");
    if (!postIDParameter) {
        postIDParameter = queryParameters.get("postid");
    }
    const [isLoading, setIsLoading] = useState(true);
    const [post, setPost] = useState({});
    const [jobId, setJobId] = useState(queryParameters.get("jobId"));
    const [shouldAllowResume, setShouldAllowResume] = useState(queryParameters.get("resume") ?? false);
    const [applicationComplete, setApplicationComplete] = useState(false);
    const appInsights = useAppInsightsContext();
    const trackSubmittedForm = useTrackEvent(appInsights, "Form Submitted");
    const trackInvalidPostId = useTrackEvent(appInsights, "Invalid Post ID");
    const trackInvalidJobId = useTrackEvent(appInsights, "Invalid Job ID");

    useEffect(() => {
        async function queryPost() {
            var result;
            if (postIDParameter) {
                const response = await fetch(`${jobPostApiUrl}?postId=${postIDParameter}`, {
                    method: "GET"
                });
                if (response.ok) {
                    result = await response.json();
                }
                else {
                    trackInvalidPostId({ properties: { postId: postIDParameter } });
                }
            }

            if (!result && jobIDParameter) {
                const response = await fetch(`${jobPostApiUrl}?jobId=${jobIDParameter}`, {
                    method: "GET"
                });
                if (response.ok) {
                    result = await response.json();
                }
                else {
                    trackInvalidJobId({ properties: { jobId: jobIDParameter } });
                }
            }
            setPost(result);

            if (result?.BoardName === "Skilled Labor Board") {
                setShouldAllowResume(true);
            }
            setJobId(result?.ReqID);
            setInputs(values => ({ ...values, jobID: result?.ReqID }));

            setIsLoading(false);
        }

        queryPost();
    }, [jobIDParameter, postIDParameter, trackInvalidJobId, trackInvalidPostId]);

    useEffect(() => {
        // check for a coookie and if that exists save
        var sourceString = "Direct Link";
        try {
            const cookieReferer = getCookie("referer");
            const requestReferer = window.recruitmentSiteReferer;
            if (cookieReferer) {
                sourceString = cookieReferer
            }
            else if (requestReferer) {
                sourceString = requestReferer;
            }
        }
        catch (error) {
            appInsights.trackTrace({ message: `Cookie Error Occurred: ${error} | ${document.cookie} | ${window.recruitmentSiteReferer}`, severityLevel: SeverityLevel.Warning });
        }

        // else use the referer header
        // else Direct Link
        setInputs(values => ({ ...values, personalInfo: { ...values.personalInfo, source: sourceString } }));
    }, []);

    const [inputs, setInputs] = useState({
        jobID: jobId,
        personalInfo: {},
        questionnaire: {},
        eeo: {
            gender: "I do not wish to answer",
            race: "I do not wish to answer",
            veteran: "I do not wish to answer",
            disability: "I do not wish to answer"
        },
        acknowledgment: false
    });

    const [currentPage, setCurrentPage] = useState(0);
    const [lastPageVisited, setLastPageVisited] = useState(0);

    const resumeUpload = createRef();

    const handlePersonalInfoChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, personalInfo: { ...values.personalInfo, [name]: value } }));
    };

    const handleQuestionnaireChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, questionnaire: { ...values.questionnaire, [name]: value } }));
    };

    const handleEEOChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, eeo: { ...values.eeo, [name]: value } }));
    };

    const handleAcknowledgmentChange = (event) => {
        const name = event.target.name;
        const value = event.target.checked;
        if (value === false && lastPageVisited > 3) {
            setLastPageVisited(3);
        }
        setInputs(values => ({ ...values, [name]: value }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        trackSubmittedForm();
        const response = await fetch(completionApiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(inputs)
        });

        setIsLoading(false);
        if (response.ok) {
            //window.location = `${redirectUrl}?external_id=${inputs.applicantId}`;
        }
        setApplicationComplete(true);
    }

    const handleSubmitGoNext = async (event) => {
        event.preventDefault();

        navigateToNextPage()
    }

    const handleApplicantCreationSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ ...inputs.personalInfo, jobId: inputs.jobID })
        });

        if (response.ok) {
            var responseObject = await response.json();
            var applicantId = responseObject.applicantId;
            setInputs(values => ({ ...values, applicantId: applicantId }));
            setIsLoading(false);
            navigateToNextPage()
        }
        else {
            setIsLoading(false);
            var result = await response.text()
            alert(`An error occurred in creating the applicant. ${result}`);
        }
    }

    const handleResumeChange = async (event) => {
        event.preventDefault();
        const fileURL = resumeUpload.current.files[0];
        const fileDataBase64 = await convertToBase64(fileURL);
        setInputs(values => ({ ...values, personalInfo: { ...values.personalInfo, resumeFileName: resumeUpload.current.files[0].name, resumeFile: fileDataBase64 } }));
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                var encodedData = fileReader.result.split(',')[1];
                resolve(encodedData);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const navigateToNextPage = () => {
        setCurrentPage(currentPage + 1);
        if (currentPage + 1 > lastPageVisited) {
            setLastPageVisited(currentPage + 1)
        }
    }

    const navigateToPageByNumber = (pageNumber) => {
        if (lastPageVisited < pageNumber) {
            return;
        }

        setCurrentPage(pageNumber);
    }

    const navigateToPreviousPage = () => {
        setCurrentPage(currentPage - 1);
    }

    const pages = [
        {
            component: <PersonalInformationForm handleChange={handlePersonalInfoChange}
                handleResumeChange={handleResumeChange}
                handleSubmit={handleApplicantCreationSubmit}
                resumeUpload={resumeUpload}
                inputs={inputs}
                shouldAllowResume={shouldAllowResume} />,
            title: "Personal Information"
        },
        {
            component: <QuestionnaireForm handleChange={handleQuestionnaireChange}
                handleSubmit={handleSubmitGoNext}
                navigateToPreviousPage={navigateToPreviousPage}
                inputs={inputs} />,
            title: "Questionnaire"
        },
        {
            component: <EqualOpportunityForm handleChange={handleEEOChange}
                handleSubmit={handleSubmitGoNext}
                navigateToPreviousPage={navigateToPreviousPage}
                inputs={inputs} />,
            title: "Equal Opportunity"
        },
        {
            component: <AcknowledgmentForm handleChange={handleAcknowledgmentChange}
                handleSubmit={handleSubmitGoNext}
                navigateToPreviousPage={navigateToPreviousPage}
                inputs={inputs} />,
            title: "Acknowledgment"
        },
        {
            component: <ReviewForm handleSubmit={handleSubmit}
                navigateToPreviousPage={navigateToPreviousPage}
                inputs={inputs} />,
            title: "Review"
        }
    ]

    const trackFormSectionView = useTrackEvent(appInsights, "Page Viewed");

    useEffect(() => {
        trackFormSectionView({ properties: { pageNumber: currentPage } });
    }, [currentPage, trackFormSectionView]);


    return (
        <div className='flex flex-col w-full h-full'>
            <img src={logo} alt="Leadpoint" className='md:max-w-lg self-center py-10 w-full' />
            {
                isLoading ?
                    <div className="grid grid-cols-1 pt-10 place-content-center">
                        <div className="flex place-content-center">
                            <LoadingSpinner style={{ height: 80, width: 80 }} className="animate-spin" />
                        </div>
                    </div>
                    :
                    <>
                        {
                            post
                                ?
                                <>
                                    {
                                    applicationComplete ?
                                            <div className="flex flex-col justify-center">
                                                <h2 className="text-5xl pb-2 font-semibold">Thank you!</h2>
                                                <p>We have recieved your application. Someone will reach out to you with more information about next steps.</p>
                                                <p>Head back to the <a className="text-blue-600 visited:text-purple-600s" href={leadpointJobBoardUrl}>Leadpoint Job Board</a> to see what jobs are currently available.</p>
                                            </div>
                                        :
                                        <>
                                            <section className="pb-2">
                                                Welcome to the Leadpoint Job Application Portal! You are currently applying for:
                                            </section>
                                            <p><span>Job Title: </span><span className="font-bold">{post.Name}</span></p>
                                            <p><span>Location: </span><span className="font-bold">{post.JobCity}, {post.JobState}</span></p>
                                            <p><span className="text-sm font-bold">If you have worked or applied for Leadpoint before, PLEASE CALL OUR RECRUITING DEPARTMENT AT 1-855-802-1741</span></p>
                                            <div className='flex justify-center space-x-8 pt-3'>
                                                {pages.map((page, index) => {
                                                    return (
                                                        <div key={`step_${index}`} className={`w-16 h-16 shadow-sm border-2 border-slate-200 rounded-full flex content-center justify-center transition duration-150 ease-in-out ${currentPage === index ? "bg-red-500" : index <= lastPageVisited ? "bg-slate-100 hover:cursor-pointer" : ""}`}
                                                            onClick={() => navigateToPageByNumber(index)}>
                                                            <div className='w-12 h-12 bg-white rounded-full flex self-center content-center justify-center'>
                                                                <span className='self-center text-xl text-slate-500 font-bold'>{index + 1}</span>
                                                            </div>
                                                        </div>)
                                                })}
                                            </div>
                                            <h3 className="text-xl self-center pt-4">{pages[currentPage].title}</h3>
                                            <div className="py-4">
                                                {pages[currentPage].component}
                                            </div>
                                        </>
                                }
                                    
                                </>
                                :
                                <div className="flex flex-col justify-center">
                                    <h2 className="text-5xl pb-2 font-semibold">Oops!</h2>
                                    <p>Looks like the job you&#39;re trying to apply for doesn&#39;t exist. </p>
                                    <p>Head back to the <a className="text-blue-600 visited:text-purple-600s" href={leadpointJobBoardUrl}>Leadpoint Job Board</a> to see what jobs are currently available.</p>
                                    <button onClick={() => window.location = leadpointJobBoardUrl}
                                        className="py-2 px-4 w-140 md:w-140 mt-10 self-center bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75" >See Current Jobs</button>
                                </div>
                        }
                    </>
            }
        </div>
    );
}

export default Home;
