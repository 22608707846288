import React from 'react';
import PropTypes from 'prop-types';

function EqualOpportunityForm({
  inputs,
  handleSubmit,
  handleChange,
  navigateToPreviousPage,
}) {
  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col">
        <h2 className="text-lg font-medium pb-1">
          U.S. Equal Employment Opportunity Information (Completion is
          voluntary)
        </h2>
        <p className="text-sm pb-1">
            Individuals seeking employment at Leadpoint Business Services are considered without regards to race, color, religion, national origin, age, sex, marital status, ancestry, physical 
            or mental disability, veteran status, or sexual orientation. You are being given the opportunity to provide the following information in order to help us comply with federal and state
            Equal Employment Opportunity/Affirmative Action record keeping, reporting, and other legal requirements.
        </p>
        <p className="text-sm pb-1">
        Completion of the form is voluntary. Refusal to provide the requested information will not subject you to any adverse treatment. Any information that you do provide will be kept confidential
         as required by law.
        </p>
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            Gender
          </span>
          <select
            name="gender"
            value={inputs.eeo.gender || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                  invalid:border-pink-500 invalid:text-pink-600
                                  focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option>I do not wish to answer</option>
            <option>Male</option>
            <option>Female</option>
            <option>Non-Binary</option>
          </select>
        </label>
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            Race/Ethnicity?
          </span>
          <select
            name="race"
            value={inputs.eeo.race || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                  invalid:border-pink-500 invalid:text-pink-600
                                  focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option
              label="I do not wish to answer"
              value="I do not wish to answer"
              selected
            >
              I do not wish to answer
            </option>
            <option
              label="American Indian or Alaskan Native"
              value="American Indian or Alaskan Native"
            >
              American Indian or Alaskan Native
            </option>
            <option
              label="Asian (Not Hispanic or Latino)"
              value="Asian (Not Hispanic or Latino)"
            >
              Asian (Not Hispanic or Latino)
            </option>
            <option
              label="Black or African American (Not Hispanic or Latino)"
              value="Black or African American (Not Hispanic or Latino)"
            >
              Black or African American (Not Hispanic or Latino)
            </option>
            <option label="Hispanic or Latino" value="Hispanic or Latino">
              Hispanic or Latino
            </option>
            <option
              label="White (Not Hispanic or Latino)"
              value="White (Not Hispanic or Latino)"
            >
              White (Not Hispanic or Latino)
            </option>
            <option
              label="Native Hawaiian or Other Pacific Islander"
              value="Native Hawaiian or Other Pacific Islander"
            >
              Native Hawaiian or Other Pacific Islander
            </option>
            <option
              label="Two or More Races (Not Hispanic or Latino)"
              value="Two or More Races (Not Hispanic or Latino)"
            >
              Two or More Races (Not Hispanic or Latino)
            </option>
          </select>
        </label>
        <p className="text-sm pb-1">
        If you believe you belong to any of the categories of protected veterans listed below, please indicate by making the appropriate selection. As a government contractor subject to Vietnam Era Veterans Readjustment Assistance Act (VEVRAA), we request this information in order to measure the effectiveness of the outreach and positive recruitment efforts we undertake pursuant to VEVRAA. Classification of protected categories is as follows:
        </p>
        <ul>
            <li className="text-sm">
                A &#34;disabled veteran&#34; is one of the following: a veteran of the U.S. military, ground, naval or air service who is entitled to compensation (or who but for the receipt of military retired pay would be entitled to compensation) under laws administered by the Secretary of Veterans Affairs; or a person who was discharged or released from active duty because of a service-connected disability.
            </li>
            <li className="text-sm">
                A &#34;recently separated veteran&#34; means any veteran during the three-year period beginning on the date of such veteran&#39;s discharge or release from active duty in the U.S. military, ground, naval, or air service.
            </li>
            <li className="text-sm">
                A &#34;recently separated veteran&#34; means any veteran during the three-year period beginning on the date of such veteran&#39;s discharge or release from active duty in the U.S. military, ground, naval, or air service.
            </li>
            <li className="text-sm">
                An &#34;Armed forces service medal veteran&#34; means a veteran who, while serving on active duty in the U.S. military, ground, naval or air service, participated in a United States military operation for which an Armed Forces service medal was awarded pursuant to Executive Order 12985.
            </li>
        </ul>
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            Veteran Status
          </span>
          <select
            name="veteran"
            value={inputs.eeo.veteran || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                  invalid:border-pink-500 invalid:text-pink-600
                                  focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option
              label="I do not wish to answer"
              value="I do not wish to answer"
              selected
            >
              I do not wish to answer
            </option>
            <option
              label="Armed Forces Service Medal Veteran"
              value="Armed Forces Service Medal Veteran"
            >
              Armed Forces Service Medal Veteran
            </option>
            <option label="Disabled Veteran" value="Disabled Veteran">
              Disabled Veteran
            </option>
            <option label="Non Veteran" value="Non Veteran">
              Non Veteran
            </option>
            <option
              label="Other Protected Veteran"
              value="Other Protected Veteran"
            >
              Other Protected Veteran
            </option>
            <option
              label="Recently Separated Veteran"
              value="Recently Separated Veteran"
            >
              Recently Separated Veteran
            </option>
            <option
              label="Special Disabled Veteran"
              value="Special Disabled Veteran"
            >
              Special Disabled Veteran
            </option>
            <option label="Veteran" value="Veteran">
              Veteran
            </option>
            <option label="Vietnam Era Veteran" value="Vietnam Era Veteran">
              Vietnam Era Veteran
            </option>
            <option
              label="I do not wish to answer"
              value="I do not wish to answer"
            >
              I do not wish to answer
            </option>
          </select>
        </label>
        <p className="text-sm pb-1">
        We endeavor to reach out to, hire, and provide equal opportunity to qualified people with disabilities. To help us measure how well we are doing, we are asking you to tell us if you have a disability or if you ever had a disability.
        </p>
        <p className="text-sm pb-1">   
            You are considered to have a disability if you have a physical or mental impairment or medical condition that substantially limits a major life activity, or if you have a history or record of such an impairment or medical condition.
            Disabilities include, but are not limited to:
        </p> 
        <ul className="list-disc text-sm pl-10">
            <li>Blindness</li>
            <li>Autism</li>
            <li>Bipolar disorder</li>
            <li>Post-traumatic stress disorder (PTSD)</li>
            <li>Deafness</li>
            <li>Cerebral palsy</li>
            <li>Major depression</li>
            <li>Obsessive compulsive disorder</li>
            <li>Cancer</li>
            <li>HIV/AIDS</li>
            <li>Multiple sclerosis (MS)</li>
            <li>Impairments requiring the use of a wheelchair</li>
            <li>Diabetes</li>
            <li>Schizophrenia</li>
            <li>Missing limbs or partially missing limbs</li>
            <li>Intellectual disability (previously called mental retardation)</li>
            <li>Epilepsy</li>
            <li>Muscular dystrophy</li>
        </ul>
        
        <label className="py-2">
          <span className="block text-sm font-medium text-slate-700">
            Disability Status
          </span>
          <select
            name="disability"
            value={inputs.eeo.disability || ""}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
                                  focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                  disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                  invalid:border-pink-500 invalid:text-pink-600
                                  focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
            required
          >
            <option
              label="I do not wish to answer"
              value="I do not wish to answer"
              selected
            >
              I do not wish to answer
            </option>
            <option
              label="Individual with Disabilities"
              value="Individual with Disabilities"
            >
              Individual with Disabilities
            </option>
            <option
              label="Individual without Disabilities"
              value="Individual without Disabilities"
            >
              Individual without Disabilities
            </option>
          </select>
        </label>

        <div className="flex justify-between flex-col md:flex-row">
          <button
            onClick={navigateToPreviousPage}
            className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-slate-400 text-white font-semibold rounded-lg shadow-md hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          >
            Back
          </button>

          <input
            type="submit"
            value="Next"
            className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
          />
        </div>
      </div>
    </form>
  );
}


EqualOpportunityForm.propTypes = {
    inputs: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    navigateToPreviousPage: PropTypes.func
}

export default EqualOpportunityForm;
