import React from 'react';
import PropTypes from 'prop-types';

function AcknowledgmentForm({
    inputs,
    handleSubmit,
    handleChange,
    navigateToPreviousPage,
  }) {
    return (
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col">
          <div className="flex flex-row items-center">
            <input
                  id="acknowledgment"
                  name="acknowledgment"
                  type="checkbox"
                  checked={inputs.acknowledgment || false}
                  onChange={handleChange}
                  required
                  className="m-4 bg-white border border-slate-300 rounded-md text-sm shadow-sm 
                                      focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
                                      disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
                                      invalid:border-pink-500 invalid:text-pink-600
                                      focus:invalid:border-pink-500 focus:invalid:ring-pink-500"
                />
            <label htmlFor="acknowledgment" className="py-2">
              <span className="text-sm font-medium text-slate-700">
                I am applying for employment with Leadpoint, and upon my agreement below, I certify that the information I have provided in the application process is accurate, truthful, and complete to the best of my knowledge. I understand and agree that any false, incomplete, or misrepresented information discovered before or after a job offer will result in rejection of my application or dismissal at any time during my employment.
              </span>
            </label>
          </div>
          <div className="flex justify-between flex-col md:flex-row">
            <button
              onClick={navigateToPreviousPage}
              className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-slate-400 text-white font-semibold rounded-lg shadow-md hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            >
              Back
            </button>

            <input
              type="submit"
              value="Next"
              className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
            />
          </div>
        </div>
      </form>
    );
}

AcknowledgmentForm.propTypes = {
    inputs: PropTypes.object,
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    navigateToPreviousPage: PropTypes.func
}
  
  export default AcknowledgmentForm;
  