import Home from "./components/Home";
import React from 'react';

const AppRoutes = [
  {
    index: true,
    element: <Home />
  }];

export default AppRoutes;
