import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './css/main.css';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
        <Layout>
          <AppInsightsContext.Provider value={reactPlugin}>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
          </Routes>
        </AppInsightsContext.Provider>
      </Layout>
    );
  }
}
