import React from 'react';
import PropTypes from 'prop-types';

const stylePhoneNumberString = (input) => {
    var result = ""
    if (input.length !== 0) {
        if (input.length <= 3) {
            result = "(" + input;
        } else if (input.length <= 6) {
            result = "(" + input.substring(0, 3) + ") " + input.substring(3);
        } else if (input.length <= 10) {
            result = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6);
        } else {
            var len = input.length;
            result = "+" + input.substring(0, len - 10) + " (" + input.substring(len - 10, len - 7) + ") " + input.substring(len - 7, len - 4) + "-" + input.substring(len - 4);
        }
    }
    return result;
}

function ReviewForm({
    inputs,
    handleSubmit,
    navigateToPreviousPage,
}) {
    var phoneNumberStyled = stylePhoneNumberString(inputs.personalInfo.mobilePhone);
    var emergencyContactPhoneNumberStyled = stylePhoneNumberString(inputs.questionnaire.emergencyContactPhoneNumber);
    
    return (
      <form onSubmit={handleSubmit}>
            <div className="flex flex-col">
                <section>
                    Please review the information below for correctness. If anything is wrong, use the back button or the page numbers above to navigate to the necessary page and resolve them before returning here to submit.
                </section>
            <section className="py-3">
                <h3 className="text-xl">Personal Information</h3>
                <div><span className="font-bold">First Name: </span>{inputs.personalInfo.firstName}</div>
                <div><span className="font-bold">Last Name: </span>{inputs.personalInfo.lastName}</div>
                <div><span className="font-bold">Address: </span>{inputs.personalInfo.address}</div>
                <div><span className="font-bold">City: </span>{inputs.personalInfo.city}</div>
                <div><span className="font-bold">Country: </span>{inputs.personalInfo.country}</div>
                <div><span className="font-bold">State: </span>{inputs.personalInfo.state}</div>
                <div><span className="font-bold">Phone Number: </span>{phoneNumberStyled}</div>
                <div><span className="font-bold">Email: </span>{inputs.personalInfo.email}</div>
            </section>
            <section className="py-3">
                <h3 className="text-xl">Questionnaire</h3>
                <div className="py-1"><span className="font-bold text-wrap">Emergency Contact Name: </span></div>
                <div>{inputs.questionnaire.emergencyContactName}</div>
                <div><span className="font-bold text-wrap">Emergency Contact Phone Number: </span></div>
                <div>{emergencyContactPhoneNumberStyled}</div>
                <div><span className="font-bold text-wrap">Are you willing to submit to a pre-employment background and substance screening?: </span></div>
                <div>{inputs.questionnaire.backgroundCheck}</div>
                <div><span className="font-bold text-wrap">Are you able to perform the essential functions of the job for which you are applying, with or without a reasonable accommodation?: </span></div>
                <div>{inputs.questionnaire.accommodations}</div>
                <div><span className="font-bold text-wrap">Are you authorized to work in the United States?: </span></div>
                <div>{inputs.questionnaire.workAuthorization}</div>
                <div><span className="font-bold text-wrap">Are you 18 or older?: </span></div>
                <div>{inputs.questionnaire.workingAge}</div>
                <div><span className="font-bold text-wrap">Have you ever applied or worked for Leadpoint before?: </span></div>
                <div><span className="font-semibold text-wrap">IF THIS APPLIES TO YOU, PLEASE CALL OUR RECRUITING DEPARTMENT AT 1-855-802-1741</span></div>
                <div>{inputs.questionnaire.previouslyApplied}</div>
                <div><span className="font-bold text-wrap">How did you hear about this position?: </span></div>
                <div>{inputs.questionnaire.referralSource}</div>
            </section>
            <section className="py-3">
                <h3 className="text-xl">Equal Employment Opportunity</h3>
                <h4 className="italic">(Completion is voluntary)</h4>
                <div><span className="font-bold">Gender: </span>{inputs.eeo.gender}</div>
                <div><span className="font-bold">Race/Ethnicity: </span>{inputs.eeo.race}</div>
                <div><span className="font-bold">Veteran Status: </span>{inputs.eeo.veteran}</div>
                <div><span className="font-bold">Disability Status: </span>{inputs.eeo.disability}</div>
            </section>
            <div className="flex justify-between flex-col md:flex-row">
                <button
                    onClick={navigateToPreviousPage}
                    className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-slate-400 text-white font-semibold rounded-lg shadow-md hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                >
                    Back
                </button>

                <input
                    type="submit"
                    value="Submit"
                    className="py-2 px-4 w-40 md:w-100 mt-10 self-center bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75"
                />
            </div>
          </div>
      </form>
    );
}


ReviewForm.propTypes = {
    inputs: PropTypes.object,
    handleSubmit: PropTypes.func,
    navigateToPreviousPage: PropTypes.func
}
  
export default ReviewForm;
  